import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { SHOW_REQUEST_A_TUTOR_FORM } from 'shared/constants/index';
import { compose } from 'redux';
import { connect } from 'react-redux';

function NotFound({ title, currentUser }) {
  return (
    <Box display="flex" flexDirection="column" m={10}>
      <Box>
        <Typography variant="h4">{title}</Typography>
      </Box>
      <Box mt={3}>
        <Typography variant="body1">
          Sorry, the page you were trying to view does not exist. Below are some
          useful links to navigate to.
        </Typography>
      </Box>
      <Box mt={3}>
        <Link href="/" variant="body1">
          Navigate Home
        </Link>
      </Box>
      <Box>
        <Link href="/find-a-tutor" variant="body1">
          Find a Tutor
        </Link>
      </Box>
      {(SHOW_REQUEST_A_TUTOR_FORM || !currentUser) && (
        <Box>
          <Link href="/request-a-tutor" variant="body1">
            Request a Tutor
          </Link>
        </Box>
      )}
    </Box>
  );
}

NotFound.propTypes = {
  title: PropTypes.string.isRequired,
  currentUser: PropTypes.shape.isRequired,
};

const mapStateToProps = state => ({
  currentUser: state.subscriptions.currentUser,
});

export default compose(connect(mapStateToProps))(NotFound);
